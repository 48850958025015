import forgotPasswordIcon from '../../assets/icons/Forgot-password-icon.png'
import gridIcon from '../../assets/icons/grid.png'
import userIcon from '../../assets/icons/userIcon.png'
import { Dialog, Paper, TextField, DialogContentText, TextareaAutosize, Checkbox, Button, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { getPasswordAPI } from '../../apiIntegeration/ApiIntegeration';
import { findDeviceID } from '../../commonFunction/commonFunction';
import { CONSTANTS } from '../../constants/Constants';

const ForgotPasswordDialog = (props) => {

    const [formData, setFormData] = useState({ mobile: '' })
    const [errorStatus, setErrorStatus] = useState({ mobile: false, invalid: false })
    const [errorMessage, setErrorMessage] = useState({ mobile: '', invalid: '' })
    const [apiStatus, setApiStatus] = useState(false)

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        if (errorStatus[name] === true) {
            if (name === "mobile") {
                const phoneNumberRegex = /^[6-9]\d{9}$/;
                if (value.match(phoneNumberRegex) && value.length === 10) {
                    clearError(name)
                }
            }
        }
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const clearError = (name) => {
        setErrorStatus((previousValue) => ({ ...previousValue, [name]: false }))
        setErrorMessage((previousValue) => ({ ...previousValue, [name]: '' }))
    }

    const onSubmit = () => {
        const obj = { mobile: false, invalid: false }
        const objMessage = { mobile: '', invalid: '' }

        if (formData.mobile === "") {
            obj.mobile = true;
            objMessage.mobile = "Please enter valid Mobile Number";
        }
        else {
            const phoneNumberRegex = /^[6-9]\d{9}$/;
            if (formData.mobile.match(phoneNumberRegex) && formData.mobile.length === 10) {
                obj.mobile = false;
                objMessage.mobile = "";
                setApiStatus(true)
                const objApi = {};
                objApi.entityCode = props.entityID;
                objApi.userName = formData.mobile;
                objApi.source = CONSTANTS.SOURCE_WEB;
                objApi.deviceId = findDeviceID();
                const apiResponse = getPasswordAPI(objApi);
                apiResponse.then((response) => {
                    if (response != undefined) {
                        if (response.status == 200) {
                            setApiStatus("success")
                            setFormData({ mobile: '' })
                            setErrorStatus({ mobile: false, invalid: false })
                            setErrorMessage({ mobile: '', invalid: '' })
                        }
                        else {
                            setApiStatus(false)
                            setErrorStatus({ mobile: false, invalid: true })
                            setErrorMessage({ mobile: '', invalid: response.data.message })
                        }
                    }
                })

            }
            else {
                obj.mobile = true;
                objMessage.mobile = "Please enter valid Mobile Number";
            }

        }

        if (Object.values(obj).every((e) => e === false)) {
            setErrorStatus(obj)
            setErrorMessage(objMessage)
        }
        else {
            setErrorStatus(obj)
            setErrorMessage(objMessage)
        }

    }

    const closeDialog = () => {
        setFormData({ mobile: '' })
        setErrorStatus({ mobile: false })
        setErrorMessage({ mobile: '' })
        props.close("forgotPassword");
    }


    return (
        <Dialog
            open={props.open}
            className='forgot-password'
        >
            <Paper className='forgot-password-popup'>
                <DialogContent>
                    <DialogContentText>
                        <div className='forgot-password-frame d-flex'>
                            <div className='forgot-password-img-block-desktop'>
                                <div className='forgot-password-txt-desktop'>
                                    “Forgot your password?”
                                </div>
                                <div className="dont-panic-txt-desktop">Don't panic, we've got you covered</div>
                                <div className='d-flex justify-content-center'>
                                    <img alt="forgot-icon" src={forgotPasswordIcon} className='forgot-password-img' />
                                </div>
                            </div>
                            <div className='forgot-password-input-block'>
                                <Close className='close-icon' onClick={closeDialog} />

                                <div className='forgot-password-img-block-mobile'>
                                    <div className='forgot-password-txt-mobile'>
                                        “Forgot your password?”
                                    </div>
                                    <div className="dont-panic-txt-mobile">Don't panic, we've got you covered</div>
                                </div>

                                {apiStatus!== "success" && <div> <div className='forgot-password-color-div'>
                                    <div className='we-will-sent-txt'>We will send you a new password</div>
                                    <div className='d-flex justify-content-center'><div><img alt="grid-icon" src={gridIcon} className='forgot-password-input-icon-grid' /><input type="text" name="entity" value={props.entityID} placeholder='Entity ID' id='entity-id' className={props.errorEntity ? 'entity-id input-type entity-id-error' : 'entity-id input-type'} onChange={onHandleChange} disabled={true} autoComplete='off' /></div></div>
                                    <div className='d-flex justify-content-center'><div><img alt="user-icon" src={userIcon} className='forgot-password-input-icon' /><input type="text" name='mobile' placeholder='Mobile Number' value={formData.mobile} id='user-id' className={errorMessage.mobile ? 'user-id input-type user-id-error' : 'user-id input-type'} onChange={onHandleChange} autoComplete='off' /></div></div>
                                    <div className='error-message'>{errorStatus.mobile ? errorMessage.mobile : errorStatus.invalid && errorMessage.invalid}</div>
                                    {/*<div className='error-message-main-div'><div className={errorReset ? "error-message-div" : "error-message-div error-message-div-hide"}>{errorResetMessage}</div></div>*/}
                                    <div className={'d-flex justify-content-center'}>
                                        {!apiStatus && <div className='submit-btn-login' onClick={onSubmit}>Get Password</div>}
                                        {apiStatus && <div className='submit-btn-login'>
                                            <svg className="spinner" viewBox="0 0 50 50">
                                                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                                            </svg></div>}

                                    </div>
                                    <div className='d-flex justify-content-center remember-signin-block'><div className='remember-password-txt'>Hey!, I remember my password. Let me</div><div className='forgot-password-sign-in-txt' onClick={closeDialog}>Sign In</div></div>

                                </div></div>}
                                {apiStatus==="success" && <div className='forgotpassword-success-div'>
                                    <div className='password-has-sent-txt'>
                                        Password has been sent to your
                                        registered mobile number
                                    </div>
                                    <div className='d-flex justify-content-center'><div className='sign-in-now-btn' onClick={closeDialog}>Sign In Now</div></div>
                                    {/*<div className='didnot-receive-msg-txt'>Didn’t receive your code yet? <span className='try-again-txt'>Try Again</span> <span id="signup-timer" className="signup-timer"></span></div>*/}
                                </div>}
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Paper>
        </Dialog>
    )

}
export default ForgotPasswordDialog;