export const API_URL = {
    GENERATE_QUESTION:process.env.REACT_APP_SERVER_URL_DATA+"data/generate-test",
    CREATE_PRACTICE_TEST:process.env.REACT_APP_SERVER_URL_DATA+"data/create-practice-test",
    GET_PACKAGE_INFO:process.env.REACT_APP_SERVER_URL_DATA+"data/get-package-info",
    PRACTICE_TEST_RESULT_INSERT:process.env.REACT_APP_SERVER_URL_DATA+"data/insert-practice-test-result",
    SUBMIT_PRACTICE_TEST_ANSWER:process.env.REACT_APP_SERVER_URL_DATA+"data/submit-practice-test-answer",
    INSERT_EVENT_TEST_RESULT:process.env.REACT_APP_SERVER_URL_DATA+"data/insert-test-answer",
    USER_LOGIN: process.env.REACT_APP_SERVER_URL_OPEN + "users/login",
    GET_PASSWORD: process.env.REACT_APP_SERVER_URL_OPEN + "users/get-password",
    USER_SIGNUP: process.env.REACT_APP_SERVER_URL_OPEN + "users/signup",
    GENERATE_DEMO_TEST:process.env.REACT_APP_SERVER_URL_DATA +"data/generate-demo-test",
    ADD_SUBSCRIPTION:process.env.REACT_APP_SERVER_URL_DATA +"data/add-subscription",
    GET_PRACTICE_TEST_RESULT:process.env.REACT_APP_SERVER_URL_DATA +"data/get-practice-test-result",
    SYNC_PRACTICE_TEST_DETAILS:process.env.REACT_APP_SERVER_URL_DATA +"data/sync-practice-test-details",
    GET_PRACTICE_TEST_RESULT_BY_ID:process.env.REACT_APP_SERVER_URL_DATA +"data/get-pt-result-by-pid",
    GET_EVENT_TEST_RESULT:process.env.REACT_APP_SERVER_URL_DATA +"data/get-event-test-result",
    SYNC_EVENT_TEST_DETAILS:process.env.REACT_APP_SERVER_URL_DATA +"data/sync-test-details",
    GET_EVENT_TEST_RESULT_BY_ID:process.env.REACT_APP_SERVER_URL_DATA +"data/get-et-result-by-eid",
    GET_MERKRTING_DATA:process.env.REACT_APP_SERVER_URL_DATA +"data/get-marketing-data-web",
    GENERATE_ORDER_ID:process.env.REACT_APP_SERVER_URL_DATA +"data/generate-order-id",
    PAYMENT_CONFIRMATION:process.env.REACT_APP_SERVER_URL_DATA +"data/payment-confirmation",
    QUESTION_REPORT:process.env.REACT_APP_SERVER_URL_DATA +"data/report-question",
    GET_SUBSCRIPTION_DATA:process.env.REACT_APP_SERVER_URL_DATA+"data/get-subscription-data",
    GET_SESSION_VALIDATE:process.env.REACT_APP_SERVER_URL_DATA+"data/verify-session",
    GET_EVENT_TEST_LIST:process.env.REACT_APP_SERVER_URL_DATA+"data/get-published-et-list",
    GET_EVENT_TEST_LIST_NEW:process.env.REACT_APP_SERVER_URL_DATA+"data/get-published-et-list-new",
    GET_EVENT_TEST_DATA:process.env.REACT_APP_SERVER_URL_DATA+"data/get-event-test-data",
    GET_EVENT_TEST_DATA_NEW:process.env.REACT_APP_SERVER_URL_DATA+"data/get-event-test-data-new",
    GET_COURSE: process.env.REACT_APP_SERVER_URL_DATA + "data/get-subscription-list",
    SUBMIT_EVENT_TEST: process.env.REACT_APP_SERVER_URL_DATA + "data/insert-user-action",
    GET_INBOX_MESSAGE_DATA:process.env.REACT_APP_SERVER_URL_DATA +"data/sync-notification-list",
    UPDATE_INBOX_MESSAGE:process.env.REACT_APP_SERVER_URL_DATA +"data/update-notification-status",
    COUPON_CODE_VALIDATE:process.env.REACT_APP_SERVER_URL_DATA +"data/validate-coupon",
    FIND_ENTITY: process.env.REACT_APP_SERVER_URL_OPEN + "users/find-entity",

}