import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from 'react';
// import Login from './userManagement/Login';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Protected from './protected/Protected';
import PublicRoute from './publicRoute/publicRoute';
import NotFound from './NotFound/NotFound';
import LoginNew from './userManagement/LoginNew.js';
const Login = lazy(()=>import('./userManagement/Login'));
const Home = lazy(()=>import('./home/Home'));
const CoursePurchaseAndroid = lazy(()=>import('./coursePurchase/coursePurchaseAndroid'));
const CoursePurchaseAndroidNew = lazy(()=>import('./coursePurchase/coursePurchaseAndroidNew'));
const CourseCard = lazy(()=>import('./courseSelection/CourseCard'))
const TestHistory =lazy(()=>import('./testHistory/testHistory'))
const InstantInstructionNew =lazy(()=>import('./Instruction/InstantInstructionNew'))
const FinalInstructionNew =lazy(()=>import('./Instruction/FinalInstructionNew'))
const InstantResultTypeNew =lazy(()=>import('./PractiseTest/InstantResultTypeNew'))
const CoursePurchase =lazy(()=>import('./coursePurchase/coursePurchase'))
const CourseBought =lazy(()=>import('./coursePurchase/courseBought'))
const FinalResultType =lazy(()=>import('./PractiseTest/FinalResultType'))
const AllCourses =lazy(()=>import('./courses/AllCourses'))
const EventTest =lazy(()=>import('./eventTest/EventTest.js'))
const TestHistoryCard =lazy(()=>import('./testHistory/testHistoryCard'))
const EventList =lazy(()=>import('./eventTest/EventTestList.js'))
const MyCourses =lazy(()=>import('./courses/MyCourses.js'))
const EventTestInstruction =lazy(()=>import('./Instruction/EventTestInstruction.js'))
const ViewHistoryEventTest =lazy(()=>import('./testHistory/testHistoryEventTest.js'))
const PublicRouteAndroid =lazy(()=>import('./publicRouteAndroid/publicRouteAndroid.js'))
const TestCustomization =lazy(()=>import('./testCustomization/TestCustomization'))
const PracticeTestSolution =lazy(()=>import('./practiceTestSolution/PracticeTestSolution'))
const EventTestSolution =lazy(()=>import('./practiceTestSolution/EventTestSolution.js'))
const CoursePurchaseWithCoupon =lazy(()=>import('./coursePurchase/coursePurchaseWithCoupon.js'))





function App() {

  // const theme = createTheme({
  //   typography: {
  //     fontFamily: "Avenir"
  //   }
  // });


  return (
    <div className="App">
      {/* <ThemeProvider theme={theme}> */}
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<PublicRoute><LoginNew /></PublicRoute>} />
            {/* <Route path='/loginNew' element={<PublicRoute><LoginNew /></PublicRoute>} /> */}
            <Route path='/home' element={<Protected><Home /></Protected>} />
            <Route path='/testCustomization' element={<Protected><TestCustomization /></Protected>} />
            <Route path='/instantInstruction' element={<Protected><InstantInstructionNew /></Protected>} />
            <Route path='/finalInstruction' element={<Protected><FinalInstructionNew /></Protected>} />
            <Route path="/practicetest"  element={<Protected><FinalResultType /></Protected>} />
            <Route path="/instantresulttype" element={<Protected><InstantResultTypeNew /></Protected>} />
            <Route path="/practiceTestSolution" element={<Protected><PracticeTestSolution /></Protected>} />
            <Route path="/eventTestSolution" element={<Protected><EventTestSolution /></Protected>} />
            <Route path="/courseSelection" element={<Protected><CourseCard /></Protected>} />
            <Route path="/viewTestHistory" element={<Protected><TestHistory /></Protected>} />
            <Route path="/viewTestHistoryEventTest" element={<Protected><ViewHistoryEventTest /></Protected>} />
            <Route path="/coursePurchase" element={<Protected><CoursePurchase /></Protected>} />
            <Route path='/allCourses' element={<Protected><AllCourses /></Protected>} />
            <Route path='/myCourses' element={<Protected><MyCourses /></Protected>} />
            <Route path='/testHistorySelection' element={<Protected><TestHistoryCard /></Protected>} />
            <Route path='/eventTest' element={<Protected><EventTest /></Protected>} />
            <Route path='/eventList' element={<Protected><EventList /></Protected>} />
            <Route path='/eventTestInstruction' element={<Protected><EventTestInstruction /></Protected>} />
            <Route path='/coursePurchaseAndroid' element={<PublicRouteAndroid><CoursePurchaseAndroid /></PublicRouteAndroid>} />
            <Route path='/purchase/:sid' element={<PublicRouteAndroid><CoursePurchaseAndroidNew /></PublicRouteAndroid>} />
            <Route path='/courseBought' element={<PublicRoute><CourseBought /></PublicRoute>} />
            <Route path='/buyCourse' element={<Protected><CoursePurchaseWithCoupon /></Protected>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;
