import { useEffect, useState } from 'react';
import './Notification.css'
import { Close } from '@mui/icons-material';
import { getEventTestResultByIDAPI, getInboxMessageAPI, inboxMessageUpdateAPI } from '../apiIntegeration/ApiIntegeration';
import { useNavigate } from "react-router-dom";
import { decryptAndDecompress, findDeviceID } from '../commonFunction/commonFunction';
import { CONSTANTS } from '../constants/Constants';
import { Skeleton } from '@mui/material';
import MessageViewDialog from '../components/Dialog/MessageViewDialog';

let status = false;

const Notification = (props) => {
    const [apiStatus, setAPIStatus] = useState("")
    const [apiMessage, setAPIMessage] = useState("")
    const [notificationData, setNotificationData] = useState([])
    const [skeletonData, setSkeletonData] = useState([{}, {}, {}, {}, {}])
    const [notificationOffset, setNotificationOffset] = useState({ offset: 0, limit: 15 })
    const [showMessage, setShowMessage] = useState({ title: '', message: '', status: false, url: '', timeStamp: null })
    const [apiNotificationApiLoading, setNotificationApiLoading] = useState(false)
    const [lastgetNoticationCount, setLastgetNoticationCount] = useState(null)

    const navigate = useNavigate();
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !status && lastgetNoticationCount > 0) {
            console.log("bottom=", notificationOffset, notificationOffset.offset + 1, notificationOffset.limit + 15)
            if (!status) {
                fetchNotification(notificationOffset.offset + 1)
                setNotificationOffset({ ...notificationOffset, ['offset']: notificationOffset.offset + 1 })
            }
        }
    }


    useEffect(() => {
        setTimeout(() => {
            document.getElementById("notification").style.width = "697px";
            document.getElementById("notification").style.display = "block";
        })

        fetchNotification(0, 15)

    }, [])

    const fetchNotification = (offset) => {
        status = true;
        setNotificationApiLoading(true)
        const obj = {};
        obj.deviceid = findDeviceID();
        obj.source = CONSTANTS.SOURCE_WEB;
        obj.limit = 15;
        obj.offset = offset * 15;

        const apiResponse = getInboxMessageAPI(obj);
        apiResponse.then((response) => {
            if (response != undefined) {
                if (response.status == 200) {
                    setNotificationApiLoading(false);
                    status = false;
                    setAPIStatus("success")

                    const arrData = JSON.parse(JSON.stringify(notificationData));
                    response.data.data.notificationData.map((e) => {
                        arrData.push(e)
                    })
                    console.log("arrData", arrData,response.data.data.notificationData)


                    setNotificationData(arrData)
                    setLastgetNoticationCount(response.data.data.notificationData.length < 15 ? 0 : response.data.data.notificationData.length)
                }
                else {
                    status = false;
                    setAPIStatus("failure")
                    setAPIMessage("Something went wrong")
                }
            }
            else {
                status = false;
                setAPIStatus("failure")
                setAPIMessage("Something went wrong")
            }
        })

    }

    const closeIconClick = () => {
        props.callBack()
    }

    const updateMessage = (e) => {
        const obj = {};
        obj.msg_id = e.msg_id;
        obj.source = CONSTANTS.SOURCE_WEB;
        obj.deviceId = findDeviceID();
        obj.commit_id = new Date().getTime();
        // let score = e.data.score;
        // let rank = e.data.rank;
        if (e.is_read == 0) {
            const apiResponse = inboxMessageUpdateAPI(obj);
            apiResponse.then((response) => {
                if (response != undefined) {
                    if (response.status == 200) {
                        setShowMessage({ title: e.ti, message: e.bo, status: true, url: e.msg_type === 2 ? e.msg_url : '', timeStamp: e.cdt })
                        let notiArr = [];
                        for (let i = 0; i < notificationData.length; i++) {
                            if (notificationData[i].msg_id == e.msg_id) {
                                notificationData[i].is_read = 1;
                                notiArr.push(notificationData[i])
                            }
                            else {
                                notiArr.push(notificationData[i])
                            }
                        }
                        setNotificationData(notiArr)
                        if (e.cd == "E102") {
                            // getEventTestResults(e.data.eid, e.data.course, rank, score)
                            getEventTestResults(e.data.eid, e.data.course)

                        }
                    }
                    else {
                        setAPIStatus("failure")
                        setAPIMessage("Something went wrong")
                    }
                }
                else {
                    setAPIStatus("failure")
                    setAPIMessage("Something went wrong")
                }
            })
        }
        else {
            setShowMessage({ title: e.ti, message: e.bo, status: true, url: e.msg_type === 2 ? e.msg_url : '', timeStamp: e.cdt })

            if (e.cd == "E102") {
                // getEventTestResults(e.data.eid, e.data.course, rank, score)
                getEventTestResults(e.data.eid, e.data.course)

            }
        }
    }

    // const getEventTestResults = (eid, course, rank, score) => {
    const getEventTestResults = (eid, course) => {
        const deviceid = findDeviceID();
        const source = CONSTANTS.SOURCE_WEB;
        const response = getEventTestResultByIDAPI(eid, course, deviceid, source);
        response.then((response) => {
            if (response != undefined) {
                if (response.status == 200) {
                    const decompressData = decryptAndDecompress(response.data.data.etResult, process.env.REACT_APP_DECRYPTION_KEY, process.env.REACT_APP_DECRYPTION_IV);

                    const studentResponseArr = [];
                    const questionDataArr = [];
                    let correctAns = 0;
                    let wrongAns = 0;
                    let unAttempted = 0;
                    for (let i = 0; i < decompressData[0].result.length; i++) {
                        const studentResponseObj = {};
                        studentResponseObj.qus_id = decompressData[0].result[i].qid;
                        studentResponseObj.ans = decompressData[0].result[i].u_res;
                        studentResponseObj.timer = decompressData[0].result[i].du;
                        studentResponseObj.subject = decompressData[0].result[i].sub;
                        studentResponseObj.chapter = decompressData[0].result[i].chap;
                        studentResponseObj.correct_option = decompressData[0].result[i].ans;
                        studentResponseObj.class = decompressData[0].result[i].cl;
                        studentResponseArr.push(studentResponseObj)
                        const questionDataObj = {};
                        questionDataObj.subject = decompressData[0].result[i].sub;
                        questionDataObj.chapter = decompressData[0].result[i].chap;
                        questionDataObj.qus_id = decompressData[0].result[i].qid;
                        questionDataObj.class = decompressData[0].result[i].cl;
                        questionDataObj.qus_json = decompressData[0].result[i].cn;
                        questionDataObj.answer_json = decompressData[0].result[i].an;
                        questionDataArr.push(questionDataObj)

                        if (decompressData[0].result[i].u_res == decompressData[0].result[i].ans) {
                            correctAns++;
                        }
                        else if (decompressData[0].result[i].u_res == "" || decompressData[0].result[i].u_res == "0") {
                            unAttempted++;
                        }
                        else if ((decompressData[0].result[i].u_res != decompressData[0].result[i].ans) && decompressData[0].result[i].u_res != "" && decompressData[0].result[i].u_res != "0") {
                            wrongAns++;
                        }
                    }
                    const { chapterArr, standardArr, subjectArr, chapterList } = findStandSubChapter(questionDataArr);
                    // navigate("/eventTestSolution",
                    //     {
                    //         state: {
                    //             questiondata: questionDataArr, studentResponse: studentResponseArr, colorResponse: studentResponseArr, duration: decompressData[0].dur, correct: correctAns, wrong: wrongAns, unattempted: unAttempted, timeTaken: decompressData[0].time_taken, count: decompressData[0].result.length,
                    //             difficultyLevel: "Medium", standardThValue: standardArr, testName: decompressData[0].t_name, subjects: subjectArr, chapter: chapterArr, courseType: "event", popUpShow: true, refId: "", courseId: course, rank: rank, score: score, totalStudent: decompressData[0].atc
                    //         },
                    //         replace: true
                    //     })

                    navigate("/eventTestSolution",
                        {
                            state: {
                                questiondata: questionDataArr, studentResponse: studentResponseArr, colorResponse: studentResponseArr, duration: decompressData[0].dur, correct: correctAns, wrong: wrongAns, unattempted: unAttempted, timeTaken: decompressData[0].time_taken, count: decompressData[0].result.length,
                                difficultyLevel: "Medium", standardThValue: standardArr, testName: decompressData[0].t_name, subjects: subjectArr, chapter: chapterArr, courseType: "event", popUpShow: true, refId: "", courseId: course, totalStudent: decompressData[0].atc
                            },
                            replace: true
                        })

                }
                else {
                    setAPIStatus("failure")
                    setAPIMessage("Something went wrong")

                }
            }
            else {
                setAPIStatus("failure")
                setAPIMessage("Something went wrong")

            }
        })
    }


    const findStandSubChapter = (questionDataArr) => {
        try {
            let standardArr = [];
            let subjectArr = [];
            let chapterArr = [];
            const chapterCounting = {}
            const chapterListObj = {}

            for (let i = 0; i < questionDataArr.length; i++) {
                if (!standardArr.includes(questionDataArr[i].class)) {
                    standardArr.push(questionDataArr[i].class)
                }

                if (!subjectArr.includes(questionDataArr[i].subject)) {
                    subjectArr.push(questionDataArr[i].subject)
                }

                if (!chapterArr.includes(questionDataArr[i].chapter)) {
                    chapterArr.push(questionDataArr[i].chapter)
                }

                if (chapterListObj.hasOwnProperty(questionDataArr[i].class + "-" + questionDataArr[i].subject.toString().toUpperCase().substring(0, 3) + "-" + questionDataArr[i].chapter)) {
                }
                else {
                    if (!chapterCounting.hasOwnProperty(questionDataArr[i].class + "-" + questionDataArr[i].subject.toString().toUpperCase().substring(0, 3))) {
                        chapterCounting[questionDataArr[i].class + "-" + questionDataArr[i].subject.toString().toUpperCase().substring(0, 3)] = 0;
                    }
                    chapterCounting[questionDataArr[i].class + "-" + questionDataArr[i].subject.toString().toUpperCase().substring(0, 3)] = chapterCounting[questionDataArr[i].class + "-" + questionDataArr[i].subject.toString().toUpperCase().substring(0, 3)] + 1;
                    chapterListObj[questionDataArr[i].class + "-" + questionDataArr[i].subject.toString().toUpperCase().substring(0, 3) + "-" + questionDataArr[i].chapter] = 1;
                }

            }
            const chapterList = [];

            for (const [key, value] of Object.entries(chapterCounting)) {
                chapterList.push(key + "(" + value + ")")
            }

            // chapterArr.sort(())

            const obj = {};
            obj.chapterArr = chapterArr;
            obj.standardArr = standardArr.sort(function (a, b) { return a - b }).map((e, i) => (i == standardArr.length - 1) ? e + "th" : e + "th");
            obj.subjectArr = subjectArr;
            obj.chapterList = chapterList;
            return obj;
            // setChapter(chapterArr)
            // setStandard(standardArr)
            // setSubject(subjectArr)
            // setChapterShort(chapterList)
        }
        catch (e) {

        }
    }

    const urlRedirect = () => {
        window.open(showMessage.url, '_blank')
    }

    return (
        <div>
            <MessageViewDialog timeStamp={showMessage.timeStamp} urlRedirect={urlRedirect} onConfirm={() => setShowMessage({ title: '', message: '', status: false, url: '', timeStamp: null })} status={showMessage.status} title={showMessage.title} message={showMessage.message} url={showMessage.url} />
            <div className='menu-div-overlay open'>
            </div>
            <div className="notification" id='notification'>

                <div className='header'><span>Notifications</span><Close className='close-icon' onClick={closeIconClick} /></div>
                {notificationData.length == 0 && apiStatus == "success" &&
                    <div className='no-notification-txt'>
                        <div>No Notification found</div></div>
                }
                <div className='data-block' onScroll={handleScroll}>
                    {notificationData.map((e) =>
                        <div className={e.is_read == 0 ? 'message-block' : 'message-block-read'} onClick={() => updateMessage(e)}>
                            <div className='title'>{e.ti}</div>
                            <div className='body'>{e.bo}</div>
                        </div>
                    )}

                    {apiNotificationApiLoading && skeletonData.map((e) =>
                        <div className='message-block'>
                            <Skeleton variant="rounded" animation="wave" height={20} className="skelton-table-row" />
                            <Skeleton variant="rounded" animation="wave" height={20} className="skelton-table-row-second" />
                        </div>

                    )}

                </div>
            </div>

        </div>
    )

}
export default Notification;