import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Avatar from '../assets/icons/AvatarNew.png'
import scoreNxtDashboard from '../assets/icons/scoreNxtDashboard.png'
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import BellIcon from '../assets/icons/BellIcon.svg'
import './toolBar.css'
import { useState } from "react";
import Notification from "../notification/Notification";
import jwtDecode from 'jwt-decode';
import { decryptDataToken } from "../commonFunction/commonFunction";
var CryptoJS = require("crypto-js");

const ToolBar = (props) => {
    const [menu, setMenu] = useState(null)
    const [notificationEnable, setNotificationEnable] = useState(false)
    const open = Boolean(menu)
    const navigate = useNavigate();

    const style = {
        width: '200px',
        height: '20px !important',
        padding: '0px 15px',
        fontSize: window.innerWidth < 900 ? '13px' : '14px'
    };

    const onLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("preferred_username");
        localStorage.removeItem("userId");
        localStorage.removeItem("code");
        localStorage.removeItem("config");
        navigate("/")

    }



    const redirectCreateTest = () => {
        navigate("/courseSelection", { state: { from: "sideBar" }, replace: true })
    }

    const handleClick = (e) => {
        setMenu(e.currentTarget);
    };

    const handleClose = () => {
        setMenu(null)
    }

    const notifcationClick = () => {
        setNotificationEnable(true)
    }

    const closeNotification = () => {
        setNotificationEnable(false)
    }


    const urlSplit = window.location.href.split("/");
    const urlValue = urlSplit[urlSplit.length - 1];

    const preferred_username = decryptDataToken(localStorage.getItem("preferred_username"))

    let showCreateTest = false;

    if (localStorage.getItem("config")) {
        const config = decryptDataToken(localStorage.getItem("config"))

        console.log("config=", config)

        config.forEach((e) => {
            if (e.course_config.pt === true) {
                return showCreateTest = true;
            }
        });

    }
    else {
        onLogout()
    }

    return (
        <div className="toolbar">
            <div className={props.sideMenu ? "heading-padding-added" : "heading-padding-removed"}><div className="heading" id="heading">{urlValue == "practicetestSolution" ? props.solutionTo === "event" ? "Event Test Solution" : "Practice Test Solution" : (urlValue == "testCustomization" || urlValue == "courseSelection" || urlValue == "viewTestHistory" || urlValue == "testHistorySelection") ? urlValue == "viewTestHistory" ? props.type === "pt" ? "Practice Test" : props.type === "et" && "Event Test" : "Practice Test" : urlValue == "home" ? <img src={scoreNxtDashboard} className="score-nxt-icon-dahboard" /> : (urlValue == "allCourses" || urlValue == "myCourses") ? "Courses" : (urlValue == "coursePurchase" || urlValue === "buyCourse") ? "Purchase" : (urlValue == "eventList" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution") && "Event Test"}</div>{urlValue == "home" ? "" : (urlValue == "allCourses" || urlValue == "myCourses") ? <div className="sub-heading">My Course / Courses</div> : (urlValue == "coursePurchase" || urlValue === "buyCourse") ? <div className="sub-heading">Purchase / Courses</div> : (urlValue == "eventList" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution") ? <div className="sub-heading">My Course / Event Test</div> : <div className="sub-heading">My Course / {urlValue == "viewTestHistory" ? props.type === "pt" ? "Practice Test" : props.type === "et" && "Event Test" : props.solutionTo === "event" ? "Event Test" : "Practice Test"}</div>}</div>
            <div className='create-test-logout'>
                {showCreateTest && <div><Button variant="outlined" className='create-test-btn' onClick={redirectCreateTest}>+ Create test</Button></div>}
                <img src={BellIcon} className="bell-icon" onClick={notifcationClick} />
                <div className="avatar-block"><img src={Avatar} className="avatar-icon" onClick={handleClick} id="basic-button" />
                    <Menu
                        id="basic-menu"
                        anchorEl={menu}
                        open={open}
                        onClose={handleClose}
                        style={{ marginTop: '17px', width: '200px' }}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        className="toolbar-menu"
                    >
                        <MenuItem disabled>{preferred_username}</MenuItem>
                        <MenuItem style={style} onClick={onLogout} >Logout</MenuItem>
                    </Menu></div>
            </div>
            {notificationEnable &&
                <Notification callBack={closeNotification} />}
        </div>
    )
}

export default ToolBar;