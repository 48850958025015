import { Dialog, Paper, TextField, DialogContentText, TextareaAutosize, Checkbox, Button, DialogContent } from '@mui/material';
import signUpIcon from '../../assets/icons/signUpIcon.png'
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import PrepareLogo from '../../assets/icons/PrepareLogo.png'
import smartPhone from '../../assets/icons/smartPhone.png'
import { signUpAPI } from '../../apiIntegeration/ApiIntegeration';
import { findDeviceID } from '../../commonFunction/commonFunction';
import { CONSTANTS } from '../../constants/Constants';


const SignUpDialog = (props) => {


    const [formData, setFormData] = useState({ mobile: '', checkbox: false })
    const [errorStatus, setErrorStatus] = useState({ mobile: false, checkbox: false, invalid: false })
    const [errorMessage, setErrorMessage] = useState({ mobile: '', checkbox: '', invalid: false })
    const [apiStatus, setApiStatus] = useState(false)


    const onHandleChange = (event) => {
        const { name, value } = event.target;
        if (errorStatus[name] === true) {
            if (name === "mobile") {
                const phoneNumberRegex = /^[6-9]\d{9}$/;
                if (value.match(phoneNumberRegex) && value.length === 10) {
                    clearError(name)
                }
            }
            else if (name === "checkbox") {
                if (event.target.checked === true) {
                    clearError(name)
                }
            }
        }
        setFormData((previousValue) => ({ ...previousValue, [name]: name === "checkbox" ? event.target.checked : value }))
    }


    const clearError = (name) => {
        setErrorStatus((previousValue) => ({ ...previousValue, [name]: false }))
        setErrorMessage((previousValue) => ({ ...previousValue, [name]: '' }))
    }

    const onSubmit = () => {
        const obj = { mobile: false, checkbox: false, invalid: false }
        const objMessage = { mobile: '', checkbox: '', invalid: false }

        if (formData.mobile === "") {
            obj.mobile = true;
            objMessage.mobile = "Please enter valid Mobile Number";
        }
        else {
            const phoneNumberRegex = /^[6-9]\d{9}$/;
            if (formData.mobile.match(phoneNumberRegex) && formData.mobile.length === 10) {
                obj.mobile = false;
                objMessage.mobile = "";
            }
            else {
                obj.mobile = true;
                objMessage.mobile = "Please enter valid Mobile Number";
            }

        }

        if (formData.checkbox === false) {
            obj.checkbox = true;
            objMessage.checkbox = "Please accept terms and conditions";
        }
        else {
            obj.checkbox = false;
            objMessage.checkbox = "";
        }

        if (Object.values(obj).every((e) => e === false)) {
            setErrorStatus(obj)
            setErrorMessage(objMessage)
            setApiStatus(true)
            const obj1 = {};
            obj1.entityCode = "S101";
            obj1.userName = formData.mobile;
            obj1.source = CONSTANTS.SOURCE_WEB;
            obj1.deviceId = findDeviceID();
            const apiResponse = signUpAPI(obj1);
            apiResponse.then((response) => {
                if (response != undefined) {
                    if (response.status == 200) {
                        setFormData({ mobile: '', checkbox: false })
                        setErrorStatus({ mobile: false, checkbox: false, invalid: false })
                        setErrorStatus({ mobile: '', checkbox: '', invalid: '' })
                        setApiStatus('success')
                    }
                    else {
                        setErrorStatus((previousValue) => ({ ...previousValue, ['invalid']: true }))
                        setErrorMessage((previousValue) => ({ ...previousValue, ['invalid']: response.data.message }))
                        setApiStatus(false)
                    }
                }
            })
        }
        else {
            setErrorStatus(obj)
            setErrorMessage(objMessage)
        }

    }

    const onTermsAndCondition = () => {
        window.open("https://scorenxt.com/terms_and_condition.html", '_blank')
    }

    const closeDialog = () => {
        setFormData({ mobile: '', checkbox: false })
        setErrorStatus({ mobile: false, checkbox: false })
        setErrorMessage({ mobile: '', checkbox: '' })
        props.close("signup");
    }

    return (
        <Dialog
            open={props.open}
            className='signup'
        >
            <Paper className='signup-popup'>
                <DialogContent>
                    <DialogContentText>
                        <div className='signup-frame d-flex'>
                            <div className='signup-img-block-desktop'>
                                <div className='signup-txt-desktop'>
                                    “Master your Success”
                                </div>
                                <div className="unleash-your-txt-desktop">Explore, Experience and Evolve to Unleash your potential with our digital practice solution</div>
                                <div className='d-flex justify-content-center'>
                                    <img alt="signup-icon" src={signUpIcon} className='signup-img' />
                                </div>
                            </div>
                            <div className='signup-input-block'>
                                <Close className='close-icon' onClick={closeDialog} />

                                <div className='signup-img-block-mobile'>

                                    <div className='signup-txt-mobile'>
                                        “Master your Success”
                                    </div>
                                    <div className="unleash-your-txt-mobile">Explore, Experience and Evolve to Unleash your potential with our digital practice solution</div>
                                </div>
                                {apiStatus !== "success" && <div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <img src={PrepareLogo} alt='Entity-Logo' className='entity-logo' />
                                        <p className='entity-name'>PrepNXT (S101)</p>
                                    </div>
                                    <div className='signup-color-div'>
                                        <div className='we-will-sent-txt'>You’re Signing Up with our default Entity</div>
                                        {/* <div className='d-flex justify-content-center'><div><img alt="grid-icon" src={gridIcon} className='signup-input-icon-grid' /><input type="text" placeholder='Entity ID' id='entity-id' className='entity-id input-type' disabled={true} value={"Entity ID: S101"} /></div></div> */}
                                        <div className='d-flex justify-content-center'><div><img alt="phone-icon" src={smartPhone} className='signup-input-icon' /><input type="text" placeholder='Mobile Number' name="mobile" id='user-id' className={errorStatus.mobile ? 'user-id input-type user-id-error' : 'user-id input-type'} value={formData.mobile} onChange={onHandleChange} autoComplete='off' /></div></div>
                                        {/*<div className='error-message-main-div'><div className={errorSignup ? "error-message-div" : "error-message-div error-message-div-hide"}>{errorSignupMessage}</div></div>*/}
                                        <div className='error-message'>{errorMessage.mobile}</div>

                                        <div className='d-flex terms-and-condition-block'><div ><Checkbox className='signup-checkbox' name="checkbox" sx={{ color: errorStatus.checkbox ? "#F38282" : "#8F9AA2", '&.Mui-checked': { color: "white" } }} onChange={onHandleChange} /></div><div className='read-and-agree-txt'>I have read and agree to the <span className='terms-and-condition-txt' onClick={onTermsAndCondition}>terms & conditions</span></div></div>
                                        <div className='error-message'>{errorStatus.checkbox ? errorMessage.checkbox : errorStatus.invalid && errorMessage.invalid}</div>
                                        <div className='d-flex justify-content-center'>
                                            {!apiStatus && <div className='submit-btn-login' onClick={onSubmit}>Sign Up</div>}
                                            {apiStatus && <div className='submit-btn-login'>
                                                <svg className="spinner" viewBox="0 0 50 50">
                                                    <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                                                </svg></div>}
                                        </div>
                                        <div className='d-flex justify-content-center remember-signin-block'><div className='already-have-txt'>Already have an account?</div><div className='signup-sign-in-txt' onClick={closeDialog}>Sign In</div></div>
                                    </div>
                                </div>}

                                {apiStatus === "success" && <div className='signup-success-div'>
                                    <div className='password-has-sent-txt'>
                                        Your Login credentials has been sent to
                                        the registered mobile number
                                    </div>
                                    <div className='d-flex justify-content-center'><div className='sign-in-now-btn' onClick={closeDialog}>Sign In Now</div></div>
                                    {/*<div className='didnot-receive-msg-txt'>Didn’t receive your credentials yet?  <span className='try-again-txt'>Try Again</span> </div>*/}
                                </div>}

                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Paper>
        </Dialog>
    )

}

export default SignUpDialog;