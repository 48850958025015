import './LoginNew.css'
import { Button, CircularProgress, Divider, InputAdornment, Menu, TextField } from '@mui/material';
import LogoBig from '../assets/icons/LogoBig.png'
import LoginBackground from '../assets/icons/LoginBackground.png'
import QRCodeNew from '../assets/icons/QRCodeNew.png'
import MobileIcon from '../assets/icons/MobileIcon.png'
import GooglePlayIcon from '../assets/icons/GooglePlayIcon.png'
import { ArrowForwardIos, Close, FastForward, GridView, GridViewOutlined, MenuOutlined, Opacity, Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import gridIcon from '../assets/icons/grid.png'
import { useEffect, useState } from 'react';
import { decryptAndDecompress, decryptDataToken, encryptDataToken, findDeviceID } from '../commonFunction/commonFunction';
import { CONSTANTS } from '../constants/Constants';
import { FindEntity, loginAPI } from '../apiIntegeration/ApiIntegeration';
import userIcon from '../assets/icons/userIcon.png'
import passwordIcon from '../assets/icons/passwordIcon.png'
import { useNavigate } from 'react-router-dom';
import ContactUsDialog from '../components/Dialog/ContactUsDialog';
import ForgotPasswordDialog from '../components/Dialog/ForgotPasswordDialog';
import SignUpDialog from '../components/Dialog/SignUpDialog';


const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'var(--login-textfield-background)',
        color: 'var(--white-color-opacity-80)',
        // height: '64px',
        borderRadius: '28px',
        '&:focus': {
            backgroundColor: 'var(--login-textfield-background)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none'
        },
        '&:focus .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none'
        }
    },
});

const CustomCircularProgress = styled(CircularProgress)({
    color: 'var(--primary-color)',
});

let successTimer;


const LoginNew = () => {

    const [authenticationStep, setAuthenticationStep] = useState({ login: 'entity', signup: '', forgotPassword: '' })
    const [formData, setFormData] = useState({ entityCode: '', userName: '', password: '' })
    const [errorStatus, setErrorStatus] = useState({ entityCode: false, userName: false, password: false, invalid: false })
    const [errorMessage, setErrorMessage] = useState({ entityCode: '', userName: '', password: '', invalid: false })
    const [openPopup, setOpenPopup] = useState({ signup: false, forgotPassword: false, contactus: false })
    const [entityData, setEntityData] = useState({ img: '', name: '', h_url: '', auth_type: '', signup_opt: false, reset_pass_opt: false })
    const [passwordVisibility, setPasswordVisibility] = useState(false)
    const [toggleMenu, setToggleMenu] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {



        return () => {
            clearInterval(successTimer)
        }
    }, [])

    const ToggleMenu = () => {
        setToggleMenu(!toggleMenu)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (errorStatus[name]) {
            setErrorStatus((previousValue) => ({ ...previousValue, [name]: false }))
            setErrorMessage((previousValue) => ({ ...previousValue, [name]: '' }))
        }
        setFormData((prevFormData) => ({ ...prevFormData, [name]: name === "entityCode" ? value.toUpperCase() : value }))
    }

    const onCheckEntity = (type) => {

        let validation = false;


        if (formData.entityCode !== "") {
            validation = true;
            setErrorStatus((previousValue) => ({ ...previousValue, ['entityCode']: false }))
            setErrorMessage((previousValue) => ({ ...previousValue, ['entityCode']: "" }))
        }
        else {
            validation = false;
            setErrorStatus((previousValue) => ({ ...previousValue, ['entityCode']: true }))
            setErrorMessage((previousValue) => ({ ...previousValue, ['entityCode']: "Please Enter valid Entity" }))
        }

        if (validation) {
            setAuthenticationStep((previousValue) => ({ ...previousValue, [type]: 'entityLoading' }))
            const obj = {};
            obj.source = CONSTANTS.SOURCE_WEB;
            obj.deviceId = findDeviceID();
            obj.entityCode = formData.entityCode;
            obj.cts = Math.floor(new Date().getTime() / 1000)
            const apiResponse = FindEntity(obj);
            apiResponse.then((response) => {
                if (response != undefined) {
                    if (response.status == 200) {
                        setAuthenticationStep((previousValue) => ({ ...previousValue, [type]: 'login' }))
                        const data = decryptAndDecompress(response.data.data.entityData, obj.cts.toString() + obj.cts.toString() + obj.cts.toString() + 'xx', obj.cts.toString() + '000000')
                        console.log("data=", data[0].entity_config)
                        if (Array.isArray(data) && data.length > 0) {
                            const config = JSON.parse(data[0].entity_config);
                            setEntityData({ img: config.logo, name: data[0].entity_name, h_url: config.h_url, auth_type: config.auth_type, signup_opt: config.signup_opt, reset_pass_opt: config.reset_pass_opt })
                        }
                    }
                    else {
                        if (response.data.message === "Entity not found") {
                            setErrorStatus((previousValue) => ({ ...previousValue, ['entityCode']: true }))
                            setErrorMessage((previousValue) => ({ ...previousValue, ['entityCode']: response.data.message }))
                        }
                        setAuthenticationStep((previousValue) => ({ ...previousValue, [type]: 'entity' }))
                        setEntityData({ img: '', name: '', h_url: '', auth_type: '', signup_opt: false, reset_pass_opt: false })
                    }
                }
            }).catch((e) => {
                setAuthenticationStep((previousValue) => ({ ...previousValue, [type]: 'entity' }))
                setEntityData({ img: '', name: '', h_url: '', auth_type: '', signup_opt: false, reset_pass_opt: false })

            })
        }


    }


    const onLogin = async () => {
        try {

            const obj = { entityCode: false, userName: false, password: false, invalid: false };
            const objMessage = { entityCode: '', userName: '', password: '', invalid: '' };



            if (formData.userName !== "") {
                obj.userName = false;
                objMessage.userName = "";
            }
            else {
                obj.userName = true;
                objMessage.userName = "Please enter valid user name";
            }


            //         }
            if (formData.password == "") {
                obj.password = true;
                objMessage.password = "Please enter valid password";
            }
            else {
                obj.password = false;
                objMessage.password = "";
            }
            setErrorStatus(obj);
            setErrorMessage(objMessage);
            const objValue = Object.values(obj)
            const errorValidation = objValue.some((val) => val === true)

            if (!errorValidation) {
                setAuthenticationStep((previousValue) => ({ ...previousValue, ['login']: 'loginLoading' }))
                const obj = {};
                obj.entityCode = formData.entityCode;
                obj.userName = formData.userName;
                obj.password = formData.password;
                obj.source = CONSTANTS.SOURCE_WEB;
                obj.deviceId = findDeviceID();
                const apiResponse = loginAPI(obj);
                apiResponse.then((response) => {
                    if (response != undefined) {
                        if (response.status == 200) {
                            const token = response.data.data.access_token;
                            console.log("token=", token)
                            const tokenDecoded = JSON.parse(atob(token.split('.')[1]))
                            console.log("atob(token.split('.')[1])=", tokenDecoded)
                            let keys = Object.keys(localStorage);
                            let tokenValue = encryptDataToken(tokenDecoded.sub);
                            for (let i = 0; i < keys.length; i++) {
                                if (keys[i].includes("_")) {
                                    let splitValues = keys[i].split("_");
                                    if (decryptDataToken(splitValues[0]) == tokenDecoded.sub) {
                                        let value = localStorage.getItem(keys[i]);
                                        let keyName = JSON.parse(JSON.stringify(keys[i]));
                                        let updatedKeyName = keyName.replace(splitValues[0], tokenValue)
                                        localStorage.setItem(updatedKeyName, value)
                                        localStorage.removeItem(keys[i])
                                    }
                                }
                            }

                            let configData = [];
                            response.data.additionalData.subscription_list.forEach((e) => {
                                const obj = {};
                                obj.course_id = e.course_id;
                                obj.course_config = JSON.parse(e.course_config);
                                configData.push(obj);
                            });
                            localStorage.setItem("code", formData.entityCode);
                            localStorage.setItem("userId", tokenValue)
                            localStorage.setItem("token", encryptDataToken(response.data.data.access_token))
                            localStorage.setItem("preferred_username", encryptDataToken(tokenDecoded.preferred_username))
                            localStorage.setItem("config", encryptDataToken(configData))
                            timerControl();
                        }
                        else {
                            setErrorStatus((previousValue) => ({ ...previousValue, ['invalid']: true }))
                            setErrorMessage((previousValue) => ({ ...previousValue, ['invalid']: response.data.message }))
                            setAuthenticationStep((previousValue) => ({ ...previousValue, ['login']: 'login' }))
                        }
                    }
                    else {
                        setAuthenticationStep((previousValue) => ({ ...previousValue, ['login']: 'login' }))
                    }
                })

            }
        }
        catch (e) {

        }
    }


    const timerControl = () => {
        let i = 0;
        successTimer = setInterval(() => {
            if (i == 1) {
                clearInterval(successTimer)
                navigate("/home")
            }
            i++;
        }, 1000)
    }

    const openDialog = (type) => {
        setOpenPopup((previousValue) => ({ ...previousValue, [type]: true }))
    }

    const closeDialog = (type) => {
        setOpenPopup((previousValue) => ({ ...previousValue, [type]: false }))
    }

    const visitWebsite = () => {
        window.open("https://scorenxt.com", '_blank')
    }

    const onTermsAndCondition = () => {
        window.open("https://scorenxt.com/terms_and_condition.html", '_blank')
    }

    const onPrivcayPolicy = () => {
        window.open("https://scorenxt.com/privacy_policy.html", '_blank')
    }

    const toggleVisibilty = () => {
        setPasswordVisibility(!passwordVisibility)
    }

    const redirectToPlayStore = () => {
        window.open("https://play.google.com/store/apps/details?id=com.lidavi.scorenxt", '_blank')
    }

    const navClick = (value) => {
        openDialog(value);
        ToggleMenu()
    }

    return (
        <div className='login-new'>
            <ContactUsDialog open={openPopup.contactus} close={closeDialog} />
            <ForgotPasswordDialog entityID={formData.entityCode} open={openPopup.forgotPassword} close={closeDialog} status={false} loading={false}  />
            <SignUpDialog open={openPopup.signup} close={closeDialog} status={false} loading={false}  />
            <header className='header'>
                <nav className='nav'>
                    <div className="nav__data">
                        <img className='logo' src={LogoBig} alt='Logo' />
                        <ul className='bigscreen-ul'>
                            <li className='link' onClick={visitWebsite}>Visit Website</li>
                            <li className='link' onClick={() => openDialog("contactus")}>Contact Us</li>
                            <li><Button variant='contained' className='signup-btn' onClick={() => openDialog("signup")}>Sign Up for free</Button></li>
                        </ul>
                        <div className={toggleMenu ? 'nav__toggle show-icon' : 'nav__toggle'} id='nav-toggle'>
                            <MenuOutlined className='nav_burger' onClick={ToggleMenu} />
                            <Close className='nav_close' onClick={ToggleMenu} />
                        </div>
                    </div>
                    <div className={toggleMenu ? 'nav__menu1' : 'nav__menu'} id='nav-menu'>
                        <ul className='nav__list'>
                            <li className='dropdown__item' onClick={()=>{visitWebsite(); ToggleMenu();}}>Visit Website</li>
                            <li className='dropdown__item' onClick={() => navClick("contactus")}>Contact Us</li>
                            <li className='dropdown__item'><Button variant='contained' className='signup-btn' onClick={() => navClick("signup")}>Sign Up for free</Button></li>
                        </ul>
                    </div>
                </nav>
            </header>

            <div className='main'>
                <section className='content'>
                    <div className='background-block'>
                        <img src={LoginBackground} className='background-img' alt='Background-Image' />
                        <div className='gradident-block' />
                    </div>

                    <div className='grid-container'>
                        <section className='grid-item section1'>
                            <div>
                                <p className='what-inside-txt'>What’s Inside ScoreNXT?</p>
                                <ul>
                                    <li>Practice anytime anywhere</li>
                                    <li>Valuable Insights</li>
                                    <li>Expertise in mock test</li>
                                </ul>
                                <p className='much-more-txt'>And much more to explore....</p>
                            </div>
                            <div className='download-box-section'>
                                <p className='download-app-txt'>Download our app ScoreNXT</p>
                                <div className='d-flex justify-content-center'>
                                    <img src={QRCodeNew} alt='QR-Code' className='qr-code' />
                                    <img src={MobileIcon} alt='Mobile-icon' className='mobile-icon' />
                                </div>
                                <div className='play-icon-block'>
                                    <img src={GooglePlayIcon} alt='GooglePlayIcon' className='google-play-btn' onClick={redirectToPlayStore} />
                                </div>
                            </div>
                        </section>
                        <section className='grid-item'>
                            <div className='login-box-section'>
                                {(authenticationStep.login === "entity" || authenticationStep.login === "entityLoading") ?
                                    <div className='welcome-box'>
                                        <p className='welcome-txt'>Welcome!</p>
                                        <p className='please-signin-txt'>Please Sign-In to your account with the given details to
                                            continue your  journey.</p>
                                    </div> :
                                    (authenticationStep.login === "login" || authenticationStep.login === "loginLoading") &&
                                    <div className='entity-logo-box'>
                                        <img src={entityData.img} alt="Enitity-Logo" className='entity-logo' />
                                        <p className='entity-name'>{entityData.name}({formData.entityCode})</p>
                                    </div>
                                }
                                <div className='text-field-section'>
                                    {(authenticationStep.login === "entity" || authenticationStep.login === "entityLoading") ?
                                        <div>
                                            <p className='type-entity-txt'>Please type your entity code to continue </p>
                                            <CustomTextField InputProps={{
                                                startAdornment: <InputAdornment position="start"><img src={gridIcon} className='grid-icon' alt='grid-icon' /></InputAdornment>,
                                            }}
                                                placeholder='Entity ID'
                                                className={errorStatus.entityCode ? 'error-entity-textfield' : 'entity-textfield'}
                                                onChange={handleChange}
                                                name='entityCode'
                                                value={formData.entityCode}
                                            />
                                            <p className='type-entity-txt'>(Default  Entity code : S101)</p>
                                            <p className='error-message'>{errorMessage.entityCode}</p>
                                        </div>
                                        : (authenticationStep.login === "login" || authenticationStep.login === "loginLoading") &&
                                        <div className='d-flex flex-column align-items-center'>
                                            <CustomTextField InputProps={{
                                                startAdornment: <InputAdornment position="start"><img src={userIcon} className='grid-icon' alt='grid-icon' /></InputAdornment>,
                                            }}
                                                placeholder='User Name'
                                                className={errorStatus.userName ? 'error-username-textfield' : 'username-textfield'}
                                                onChange={handleChange}
                                                name='userName'
                                                value={formData.userName}
                                            />
                                            <p className='error-message'>{errorMessage.userName}</p>
                                            <CustomTextField InputProps={{
                                                startAdornment: <InputAdornment position="start"><img src={passwordIcon} className='grid-icon' alt='grid-icon' /></InputAdornment>,
                                                endAdornment: <InputAdornment position="end">{passwordVisibility ? <Visibility onClick={toggleVisibilty} sx={{ color: 'var(--white-color-opacity-80)', cursor: 'pointer' }} /> : <VisibilityOff onClick={toggleVisibilty} sx={{ color: 'var(--white-color-opacity-80)', cursor: 'pointer' }} />}</InputAdornment>,
                                            }}
                                                placeholder='Password'
                                                className={errorStatus.password ? 'error-password-textfield' : 'password-textfield'}
                                                onChange={handleChange}
                                                name='password'
                                                type={passwordVisibility ? 'text' : 'password'}
                                                value={formData.password}
                                            />
                                            <p className='error-message'>{errorStatus.password ? errorMessage.password : errorStatus.invalid && errorMessage.invalid}</p>

                                        </div>

                                    }

                                    <Button variant='contained' className='continue-btn' onClick={authenticationStep.login === "entity" ? () => onCheckEntity("login") : authenticationStep.login === "login" && onLogin}>{authenticationStep.login === "entity" ? <> Continue <ArrowForwardIos sx={{ color: 'var(--primary-color)' }} className='arrow-forward-icon' /></> : (authenticationStep.login === "entityLoading" || authenticationStep.login === "loginLoading") ? <CustomCircularProgress size={22} thickness={5} /> : authenticationStep.login === "login" && "Login"}  </Button>

                                </div>


                            </div>

                            {entityData.reset_pass_opt === false ? <p className='forgot-password-block'>Unsure of your entity code? No worries! Visit Help.</p> : <p className='forgot-password-block'><span className='forgot-password-txt'>Forget your password?</span><span className='click-here-txt' onClick={() => openDialog("forgotPassword")}>Click here</span></p>}

                        </section>
                    </div>

                </section>
                <footer className='footer'>
                    <span>Copyright@2024 ScoreNXT.com</span>
                    <div className='bottom-link-block'><span>Help</span> <Divider component="span" orientation="vertical" className='divider' flexItem /> <span onClick={onTermsAndCondition}>Terms and Conditions</span> <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.20)" }} className='divider' component="span" orientation="vertical" flexItem /> <span onClick={onPrivcayPolicy}>Privacy Policy</span></div>

                </footer>
            </div>
        </div>
    )

}

export default LoginNew;