import { findTokenAndUserID } from "../commonFunction/commonFunction";
import { API_URL } from "../constants/ApiConstants"
import { CONSTANTS } from "../constants/Constants"
import axios from 'axios';

export const getQuestionCollection = async (packageName, packageData, count, timeStamp, deviceId, source) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GENERATE_QUESTION
    const response = await axios({
      url: URL,
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT,
      data: {
        "packageName": packageName,
        "packageData": packageData,
        "count": count,
        "userId": userId,
        "ec": localStorage.getItem("code"),
        "deviceId": deviceId,
        "source": source,
        "cts": timeStamp
      }
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}


export const createPracticeTest = async (cid, packageData, count, timeStamp, deviceId, source, resultType, difficulty, duration) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.CREATE_PRACTICE_TEST
    const response = await axios({
      url: URL,
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT,
      data: {
        // "packageName": packageName,
        // "packageData": packageData,
        // "count": count,
        // "userId": userId,
        // "deviceId": deviceId,
        // "source": source,
        // "cts": timeStamp
        "userId": userId,
        "ec": localStorage.getItem("code"),
        "deviceId": deviceId,
        "source": source,
        "ec": localStorage.getItem("code"),
        "cid": cid,
        "count": count,
        "cts": timeStamp,
        "resultType": resultType === "Instant" ? "" + 0 : resultType === "Final" && "" + 1,
        "dLevel": difficulty,
        "duration": duration * 60,
        "packageData": packageData

      }
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}


export const getPackageInfo = async (courseId, deviceid, source) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GET_PACKAGE_INFO
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        packagename: courseId,
        deviceid: deviceid,
        source: source
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}



// export const getSubscriptionData = async () => {
//   try {
//     const { userId, accessToken } = findTokenAndUserID();
//     var URL = API_URL.GET_SUBSCRIPTION_DATA
//     const response = await axios({
//       url: URL,
//       method: CONSTANTS.GET_TXT,
//       headers: {
//         "Content-Type": "application/json",
//         'Accept': "application/json",
//         "x-api-key": process.env.REACT_APP_AWS_API_KEY,
//         "authorization": accessToken,
//         userid: userId,
//         deviceid: '8e8b7863-6e49',
//         source: 'web'
//       },
//       timeout: CONSTANTS.TIMEOUT
//     })
//     return response
//   }
//   catch (e) {
//     return e.response;
//   }
// }


export const insertPracticeTestResult = async (value) => {
  try {

    const { timeStamp, id, count, resultPercentage, result, timeTaken, tName, diff, dur, courseId, refId, deviceId, source } = value;
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.SUBMIT_PRACTICE_TEST_ANSWER
    const response = await axios({
      url: URL,
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT,
      data: {
        // "pid": id,
        // "dt": timeStamp,
        // "total": count,
        // "percentage": resultPercentage,
        // "resultJson": result,
        // "userId": userId,
        // "deviceId": deviceId,
        // "source": source,
        // "timeTaken": timeTaken,
        // "tName": tName,
        // "diff": diff,
        // "dur": dur,
        // "courseId": courseId,
        // "refId": refId
        "userId": userId,
        "ec": localStorage.getItem("code"),
        "deviceId": deviceId,
        "source": source,
        "ec": localStorage.getItem("code"),
        "testId": refId,
        "testAnswers": result
      }
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const insertEventTestResult = async (value) => {
  try {

    const { refId, deviceId, source, qid, ac_ans, isMarked, commitId, testType, cid, remaining_time, ac_type } = value;
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.INSERT_EVENT_TEST_RESULT
    const response = await axios({
      url: URL,
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT,
      data: {
        // "pid": id,
        // "dt": timeStamp,
        // "total": count,
        // "percentage": resultPercentage,
        // "resultJson": result,
        // "userId": userId,
        // "deviceId": deviceId,
        // "source": source,
        // "timeTaken": timeTaken,
        // "tName": tName,
        // "diff": diff,
        // "dur": dur,
        // "courseId": courseId,
        // "refId": refId
        "userId": userId,
        "ec": localStorage.getItem("code"),
        "deviceId": deviceId,
        "source": source,
        "ec": localStorage.getItem("code"),
        "testId": refId,
        "qid": qid,
        "ac_ans": ac_ans,
        "isMarked": isMarked,
        "commitId": commitId,
        "testType": testType,
        "cid": cid,
        "remaining_time": remaining_time,
        "ac_type": ac_type
      }
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}


export const insertPracticeTestResultNew = async (value) => {
  try {

    const { timeStamp, id, count, resultPercentage, result, timeTaken, tName, diff, dur, courseId, refId, deviceId, source } = value;
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.SUBMIT_PRACTICE_TEST_ANSWER
    const response = await axios({
      url: URL,
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT,
      data: {
        // "testId": id,
        // "dt": timeStamp,
        // "total": count,
        // "percentage": resultPercentage,
        // "resultJson": result,
        // "userId": userId,
        // "deviceId": deviceId,
        // "source": source,
        // "timeTaken": timeTaken,
        // "tName": tName,
        // "diff": diff,
        // "dur": dur,
        // "courseId": courseId,
        // "refId": refId

        "userId": userId,
        "ec": localStorage.getItem("code"),
        "deviceId": deviceId,
        "source": source,
        "ec": localStorage.getItem("code"),
        "testId": refId,
        "testAnswers": result
      }
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const insertEventTest = async (value) => {
  try {

    const { timeStamp, id, count, resultPercentage, result, timeTaken, tName, diff, dur, courseId, refId, sdt, edt, eid, deviceId, source } = value;
    const { userId, accessToken } = findTokenAndUserID();

    var URL = API_URL.SUBMIT_EVENT_TEST
    const response = await axios({
      url: URL,
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT,
      data: {
        "al": result,
        "userId": userId,
        "ec": localStorage.getItem("code"),
        "deviceId": deviceId,
        "source": source,
        "sdt": JSON.parse(sdt),
        "edt": edt,
        "eid": eid
      }
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const signUpAPI = async (value) => {
  try {
    const { entityCode, userName, password, source, deviceId } = value;

    var URL = API_URL.USER_SIGNUP
    const response = await axios({
      url: URL,
      data: {
        entityCode: entityCode,
        userName: userName,
        source: source,
        deviceId: deviceId,
        deviceToken: process.env.REACT_APP_DEVICE_TOKEN
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}


export const loginAPI = async (value) => {
  try {
    const { entityCode, userName, password, source, deviceId } = value;

    var URL = API_URL.USER_LOGIN
    const response = await axios({
      url: URL,
      data: {
        entityCode: entityCode,
        userName: userName,
        password: password,
        source: source,
        deviceId: deviceId,
        deviceToken: process.env.REACT_APP_DEVICE_TOKEN
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const getPasswordAPI = async (value) => {
  try {

    const { entityCode, userName, password, source, deviceId } = value;
    var URL = API_URL.GET_PASSWORD
    const response = await axios({
      url: URL,
      data: {
        entityCode: entityCode,
        userName: userName,
        source: source,
        deviceId: deviceId
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}


export const generateDemoTest = async (value) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    const { source, deviceId } = value;

    var URL = API_URL.GENERATE_DEMO_TEST
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const addSubscriptionAPI = async (value) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    const { source, deviceId, courseId } = value;

    var URL = API_URL.ADD_SUBSCRIPTION
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId,
        courseId: courseId
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const generateOrderIDAPI = async (value) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    const { source, deviceId, courseId, categoryId, couponCode } = value;

    var URL = API_URL.GENERATE_ORDER_ID
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId,
        courseId: courseId,
        categoryId: categoryId,
        couponCode: couponCode

      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const generateOrderIDAPIApp = async (value) => {
  try {

    const { source, deviceId, courseId, categoryId, userId, sid, couponCode } = value;

    var URL = API_URL.GENERATE_ORDER_ID
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId,
        courseId: courseId,
        categoryId: categoryId,
        sid: sid,
        couponCode: couponCode
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": "AAA"
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const paymentConfirmationAPI = async (value) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    const { source, deviceId, orderId, PaymentId, customOrderId, paymentStatus } = value;

    var URL = API_URL.PAYMENT_CONFIRMATION
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId,
        orderId: orderId,
        paymentId: PaymentId,
        customOrderId: customOrderId,
        paymentStatus: paymentStatus
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}


export const paymentConfirmationAPIApp = async (value) => {
  try {

    const { source, deviceId, orderId, PaymentId, customOrderId, paymentStatus, userId } = value;

    var URL = API_URL.PAYMENT_CONFIRMATION
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId,
        orderId: orderId,
        paymentId: PaymentId,
        customOrderId: customOrderId,
        paymentStatus: paymentStatus
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": "AAA"
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}




export const questionReportAPI = async (value) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    const { source, deviceId, qid, reason, description } = value;

    var URL = API_URL.QUESTION_REPORT
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId,
        qid: qid,
        reason: reason,
        description: description
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}




export const getPracticeTestResultAPI = async (value, deviceid, source) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GET_PRACTICE_TEST_RESULT
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source
      },
      params: {
        "courseId": value
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}


export const syncPracticeTestDetails = async (value, deviceid, source, ttype, limit, offset) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();

    var URL = API_URL.SYNC_PRACTICE_TEST_DETAILS
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source,
        ec: localStorage.getItem("code")
      },
      params: {
        ttype: ttype === "pt" ? 0 : ttype === "et" && 1,
        limit: limit,
        offset: offset,
        courseId:value
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}


export const getEventTestResultAPI = async (value, deviceid, source) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GET_EVENT_TEST_RESULT
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source
      },
      params: {
        "courseId": value
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}


export const syncEventTestDetails = async (value, deviceid, source) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.SYNC_EVENT_TEST_DETAILS
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source
      },
      params: {
        "testId": value
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}



export const getMarketingDataAPI = async (value) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GET_MERKRTING_DATA
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: value.deviceid,
        source: value.source
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}


export const getPracticeTestResultByIDAPI = async (value, courseId, deviceid, source) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GET_PRACTICE_TEST_RESULT_BY_ID
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source
      },
      params: {
        "pid": value,
        "courseId": courseId
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}


export const syncPracticeTestDetailsByID = async (value, courseId, deviceid, source, ttype) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.SYNC_PRACTICE_TEST_DETAILS
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source,
        ec: localStorage.getItem("code")
      },
      params: {
        "testId": value,
        ttype: ttype === "pt" ? 0 : ttype === "et" && 1
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}

export const getEventTestResultByIDAPI = async (value, courseId, deviceid, source) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GET_EVENT_TEST_RESULT_BY_ID
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source
      },
      params: {
        "eid": value,
        "courseId": courseId
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}




export const getEventTestDataAPI = async (value) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    const { eid, cts, deviceid, source } = value;
    var URL = API_URL.GET_EVENT_TEST_DATA
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source
      },
      params: {
        "eid": eid,
        "cts": cts
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}


export const getEventTestDataAPINew = async (value) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    const { test_id, cts, deviceid, source } = value;
    var URL = API_URL.GET_EVENT_TEST_DATA_NEW
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        deviceid: deviceid,
        source: source,
        ec: localStorage.getItem("code")
      },
      params: {
        "testId": test_id,
        "cts": cts
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}

export const getEventTestListAPI = async (value) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    const { deviceid, source } = value;

    var URL = API_URL.GET_EVENT_TEST_LIST
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        "userid": userId,
        ec: localStorage.getItem("code"),
        "deviceid": deviceid,
        "source": source
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}


export const getEventTestListAPINew = async (value) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    const { deviceid, source } = value;

    var URL = API_URL.GET_EVENT_TEST_LIST_NEW
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        "userid": userId,
        "ec": localStorage.getItem("code"),
        "deviceid": deviceid,
        "source": source,
        "ec": localStorage.getItem("code")
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}




export const getSessionValidate = async (value) => {
  try {
    //    const { userId, accessToken } = findTokenAndUserID();
    var URL = API_URL.GET_SESSION_VALIDATE
    const response = await axios({
      url: URL,
      data: value,
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": "AAAA"
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const GetCourseListApi = async (value) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    const { deviceid, source } = value;

    const response = await axios(
      {
        url: API_URL.GET_COURSE,
        method: CONSTANTS.GET_TXT,
        headers: {
          "Content-Type": "application/json",
          'Accept': "application/json",
          "x-api-key": process.env.REACT_APP_AWS_API_KEY,
          "authorization": accessToken,
          "userid": userId,
          "ec": localStorage.getItem("code"),
          "deviceid": deviceid,
          "source": source
        },
        timeout: CONSTANTS.TIMEOUT
      }
    )
    return response
  }
  catch (e) {
    if (!e.response) {
      let resObj = {}
      resObj['response'] = { status: 408, data: { message: "Timeout Error" } }
      return resObj.response
    }
    else {
      return e.response
    }
  }
}


export const getInboxMessageAPI = async (value) => {
  try {
    const { userId, accessToken } = findTokenAndUserID();
    const { deviceid, source,limit,offset } = value;

    var URL = API_URL.GET_INBOX_MESSAGE_DATA
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken,
        userid: userId,
        ec: localStorage.getItem("code"),
        deviceid: deviceid,
        source: source
      },
      params: {
        limit: limit,
        offset: offset
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}



export const inboxMessageUpdateAPI = async (value) => {
  try {

    const { userId, accessToken } = findTokenAndUserID();
    const { source, deviceId, msg_id,commit_id } = value;

    var URL = API_URL.UPDATE_INBOX_MESSAGE
    const response = await axios({
      url: URL,
      data: {
        commitId:commit_id,
        userId: userId,
        ec: localStorage.getItem("code"),
        source: source,
        deviceId: deviceId,
        msgId: msg_id
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": accessToken
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}


export const verifyCouponCodeAPI = async (value) => {
  try {

    const { userId, deviceId, source, courseId, categoryId, sid, couponCode } = value;

    var URL = API_URL.COUPON_CODE_VALIDATE
    const response = await axios({
      url: URL,
      data: {
        userId: userId,
        ec: localStorage.getItem("code"),
        deviceId: deviceId,
        source: source,
        courseId: courseId,
        categoryId: categoryId,
        sid: sid,
        couponCode: couponCode
      },
      method: CONSTANTS.POST_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        "authorization": "aaa"
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response;
  }
  catch (e) {
    return e.response;
  }
}



export const FindEntity = async (value) => {
  try {
    const { deviceid, source, entityCode, cts } = value;

    var URL = API_URL.FIND_ENTITY
    const response = await axios({
      url: URL,
      method: CONSTANTS.GET_TXT,
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        "x-api-key": process.env.REACT_APP_AWS_API_KEY,
        deviceid: deviceid,
        source: source
      },
      params: {
        entityCode: entityCode,
        cts: cts
      },
      timeout: CONSTANTS.TIMEOUT
    })
    return response
  }
  catch (e) {
    return e.response;
  }
}




