import pako from 'pako';
import { Buffer } from 'buffer';
import { CONSTANTS } from '../constants/Constants';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
var CryptoJS = require("crypto-js");




export const decryptAndDecompress = (encryptedText, key, iv) => {

    const decryptedText = decryptWithKeyAndIV(encryptedText, key, iv);
    const decompressString = (compressedString) => {
        const compressedData = Buffer.from(compressedString, 'hex');
        const decompressedData = pako.inflate(compressedData, { to: 'string' });
        return decompressedData;
    };
    if (typeof window !== 'undefined' && !window.Buffer) {
        window.Buffer = Buffer;
    }
    const compressedString = decryptedText;
    const decompressedString = decompressString(compressedString);
    return JSON.parse(decompressedString)
}



const decryptWithKeyAndIV = (encryptedText, key, iv) => {
    try {
        const encryptedData = CryptoJS.enc.Base64.parse(encryptedText);
        const parsedKey = CryptoJS.enc.Utf8.parse(key);
        const parsedIV = CryptoJS.enc.Utf8.parse(iv);

        const decrypted = CryptoJS.AES.decrypt(
            {
                ciphertext: encryptedData
            },
            parsedKey,
            {
                iv: parsedIV,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        return decryptedText;
    }
    catch (e) {

    }
};


export const createStudentResponseStructue = (parseData) => {
    try {
        const answerCreation = [];
        for (let i = 0; i < parseData.length; i++) {
            const ansArr = parseData[i].answer_json.filter((e) => e.ans && e.ans);
            let correctValue = ""
            ansArr.map((e) => e.ans.map((e1) => correctValue = e1.t));
            answerCreation.push({ ans: "", mark: 0, qus_id: parseData[i].qus_id, correct_option: correctValue, timer: 1, subject: parseData[i].qus_subject, chapter: parseData[i].qus_chapter, ts: 0, commit_id: null, ttype: 0, remaining_time: null })
        }

        return answerCreation;
    }
    catch (e) {

    }
}


export const findHoursMinutesSec = (seconds) => {
    try {
        let hrs = Math.floor(seconds / 3600);
        let mins = Math.floor((seconds % 3600) / 60);
        let secs = Math.floor(seconds % 60);
        const obj = {};
        obj.hrs = hrs;
        obj.mins = mins;
        obj.secs = secs;
        return obj;
    }
    catch (e) {

    }
}

export const removeStoargePracticeTest = () => {
    try {
        localStorage.removeItem("resultType");
        localStorage.removeItem("difficultyLevel");
        localStorage.removeItem("studentResponse");
        localStorage.removeItem("duration");
        localStorage.removeItem("standard");
        localStorage.removeItem("subjects");
        localStorage.removeItem("packageData");
        localStorage.removeItem("remainingTimer");
        localStorage.removeItem("testName");
        localStorage.removeItem("chapter");
        localStorage.removeItem("totalQuestion");
        localStorage.removeItem("questiondata");
        localStorage.removeItem("colorResponse");
        localStorage.removeItem("standardThValue");
        localStorage.removeItem("acceptTerms")
        localStorage.removeItem("testType")
        localStorage.removeItem("testCreatedDate")
    }
    catch (e) {

    }
}

export const findResultAndPercentage = (studentResponse, questionTimerCount, questionPosition) => {
    try {
        let correct = 0;
        let result = [];
        const studentResponseAnswerArr = JSON.parse(JSON.stringify(studentResponse))
        if (questionTimerCount != 0) {
            studentResponseAnswerArr[questionPosition - 1].timer = questionTimerCount;
        }
        for (let i = 0; i < studentResponseAnswerArr.length; i++) {
            if (studentResponseAnswerArr[i].ans == studentResponseAnswerArr[i].correct_option) {
                correct++;
            }
            const obj = {};
            obj.qid = studentResponseAnswerArr[i].qus_id;
            obj.u_res = studentResponseAnswerArr[i].ans;
            obj.du = studentResponseAnswerArr[i].timer;
            obj.mark = studentResponseAnswerArr[i].mark;
            obj.commit_id = studentResponseAnswerArr[i].commit_id;
            obj.ttype = studentResponseAnswerArr[i].ttype;
            obj.remaining_time = studentResponseAnswerArr[i].remaining_time;
            result.push(obj)
        }
        let percetage = (correct / studentResponseAnswerArr.length) * 100;
        const obj = {};
        obj.percetage = Math.round(percetage);
        obj.result = result;
        return obj
    }
    catch (e) {

    }
}

export const encryptData = (value) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(value), (decryptDataToken(localStorage.getItem("userId"))).substring(0, 4) + "" + (process.env.REACT_APP_STORAGE_ENCRYPTION_KEY).substring(0, 4) + "" + (decryptDataToken(localStorage.getItem("preferred_username"))).substring(0, 4) + "" + (process.env.REACT_APP_STORAGE_ENCRYPTION_KEY).substring(4, 12) + "" + (decryptDataToken(localStorage.getItem("userId"))).substring(4, 8) + "" + (process.env.REACT_APP_STORAGE_ENCRYPTION_KEY).substring(12, 16) + "" + (decryptDataToken(localStorage.getItem("preferred_username"))).substring(4, 8)).toString()
    }
    catch (e) {

    }
}

export const encryptDataToken = (value, data) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(value), process.env.REACT_APP_STORAGE_ENCRYPTION_KEY).toString()
    }
    catch (e) {

    }
}

export const decryptDataToken = (encryptedValue) => {

    try {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, process.env.REACT_APP_STORAGE_ENCRYPTION_KEY)

        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    }
    catch (e) {
    }

}



export const decryptData = (encryptedValue) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, (decryptDataToken(localStorage.getItem("userId"))).substring(0, 4) + "" + (process.env.REACT_APP_STORAGE_ENCRYPTION_KEY).substring(0, 4) + "" + (decryptDataToken(localStorage.getItem("preferred_username"))).substring(0, 4) + "" + (process.env.REACT_APP_STORAGE_ENCRYPTION_KEY).substring(4, 12) + "" + (decryptDataToken(localStorage.getItem("userId"))).substring(4, 8) + "" + (process.env.REACT_APP_STORAGE_ENCRYPTION_KEY).substring(12, 16) + "" + (decryptDataToken(localStorage.getItem("preferred_username"))).substring(4, 8))
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    }
    catch (e) {
    }
}

export const decryptUserID = (encryptedValue) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, process.env.REACT_APP_STORAGE_ENCRYPTION_KEY)
        return bytes.toString(CryptoJS.enc.Utf8)
    }
    catch (e) {
    }
}

export const findTokenAndUserID = () => {
    try {
        let userId = '';
        let accessToken = '';
        if (localStorage.getItem("userId")) {
            const userIdBytes = decryptDataToken(localStorage.getItem('userId'));
            userId = userIdBytes.toString(CryptoJS.enc.Utf8);
            const tokenBytes = decryptDataToken(localStorage.getItem('token'));
            accessToken = 'Bearer ' + tokenBytes.toString(CryptoJS.enc.Utf8);
            const obj = {}
            obj.userId = userId;
            obj.accessToken = accessToken;
            return obj;
        }
    }
    catch (e) {
    }
}

export const ResponseDataKeyFind = (response) => {

    if (response.hasOwnProperty("data")) {
        if (response.data.hasOwnProperty("data")) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }

}

const setDeviceID = () => {
    const deviceId = uuidv4();
    return deviceId;
}

export const findDeviceID = () => {
    let deviceId = '';
    if (localStorage.getItem("deviceId")) {
        deviceId = localStorage.getItem("deviceId");
    }
    else {
        deviceId = setDeviceID();
        localStorage.setItem("deviceId", deviceId)
    }

    return deviceId;
}

export const findRemainingTime = (data, testId) => {


    let arr = [];
    data.forEach((e) => {
        if (e.remaining_time !== null) {
            arr.push(e)
        }
    })

    if (arr.length == 1) {
        return arr[0].remaining_time;
    }
    else if (arr.length > 1) {
        const sorted_arr = arr.sort(function (a, b) { return (a.remaining_time - b.remaining_time); })

        return sorted_arr[0].remaining_time
    }
    else {
        return null;
    }
}

export const findCrtWrngUnattemped = (data) => {
    let obj = { correct: 0, wrong: 0, unattempted: 0 };
    data.forEach((e) => {
        if (e.ac_ans === null) {
            obj.unattempted = obj.unattempted + 1;
        }
        else if (e.ac_ans === e.correct_ans) {
            obj.correct = obj.correct + 1;
        }
        else if (e.ac_ans !== e.correct_ans) {
            obj.wrong = obj.wrong + 1;
        }
    })

    return obj;

}

export const findCrtWrngUnattempedHistory = (data) => {
    let obj = { correct: 0, wrong: 0, unattempted: 0 };
    data.forEach((e) => {
        if (e.ac_ans === null) {
            obj.unattempted = obj.unattempted + 1;
        }
        else if (e.ac_ans === e.correct_ans) {
            obj.correct = obj.correct + 1;
        }
        else if (e.ac_ans !== e.correct_ans) {
            if (e.ac_type === 0)
                obj.wrong = obj.wrong + 1;
        }
    })

    return obj;

}

export const findAccuracy = (correct, totalQuestion) => {
    let percetage = (correct / totalQuestion) * 100;
    return percetage;
}


export const sortingByCommitID = (data) => {
    const sorted_arr = data.sort(function (a, b) { return (a.commit_id - b.commit_id); })
    return sorted_arr;
}

export const arrayFindIndex = (data, qid) => {

    let a = null;
    data.forEach((e, i) => {
        if (e.qus_id === qid) {
            a = i;
        }
    })
    return a;

}

export const updateConfig = (value) => {

    try {

        let configData = [];
        value.forEach((e) => {
            const obj = {};
            obj.course_id = e.course_id;
            obj.course_config = JSON.parse(e.course_config);
            configData.push(obj);
        });

        const config = decryptDataToken(localStorage.getItem("config"));

        let diffJSONValue = false;

        configData.forEach((e) => {

            config.forEach((e1) => {
                if (e1.course_id === e.course_id) {
                    if (e1.pt !== e.pt) {
                        diffJSONValue = true;
                    }
                }
            })

        })




        if (config.length !== configData.length) {
            localStorage.setItem("config", encryptDataToken(configData))
            console.log("both data are different", config, configData)
        }
        else {
            if (!diffJSONValue) {
                localStorage.setItem("config", encryptDataToken(configData))
            }
            console.log("both data are same", config, configData,diffJSONValue)
        }
    }
    catch (e) {
       
    }


}


