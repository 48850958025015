import { DoubleDigitNumber } from "./DoubleDigitNumber";

export const TimeFormat = (value) => {
    const date = new Date(value * 1000);

    let hours = null;
    let am_pm = null;

    if (date.getHours() < 13 && date.getHours() > 0) {
        hours = date.getHours();
    }
    else if (date.getHours() === 0) {
        hours = 12;
    }
    else {
        hours = date.getHours() - 12;
    }

    if (date.getHours() < 12) {
        am_pm = "AM";
    }
    else {
        am_pm = "PM";
    }

    return DoubleDigitNumber(hours) + ":" +DoubleDigitNumber(date.getMinutes()) + " " + am_pm;
}