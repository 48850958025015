import SideBar from "../sideBar/sideBar";
import ToolBar from "../toolBar/toolBar";
import Lottie from "lottie-react";
import ErrorJSON from '../assets/lottie/404-error.json'
import './NotFound.css'
import { Button } from '@mui/material'
import { useNavigate} from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Cached';



const NotFound = () => {

    const navigate = useNavigate();

    const onRefresh = () => {
        navigate("/home",{replace:true})
    }
    return (
        <div className="not-found">
            <SideBar />
            <ToolBar />
            <div className='content-layout'>
                <div className="error-body">
                    <div className="error-content">
                    <Lottie animationData={ErrorJSON} loop={true} className='page-not-found-lottie' />
                    <div className="looks-like-txt">"Looks like you took a path to Nowhere Land! Please U-Turn to dashboard with a quick refresh!"</div>
                     <div className="button-block">
                     <Button variant="contained" className='refresh-btn' onClick={onRefresh}><RefreshIcon className="refresh-icon" /> Refresh</Button>
                </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;