import './ContactUsDialog.css'
import { Dialog, Paper, TextField, DialogContentText, TextareaAutosize, Checkbox, Button, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import locationIconContactus from "../../assets/icons/locationIconContactus.png"
import youtubeIcon from "../../assets/icons/youtubeIcon.png"
import emailContactUs from "../../assets/icons/emailContactUs.png"

const ContactUsDialog = (props) => {



    return (

        <Dialog
            open={props.open}
            className='contact-us-popup-header'
        >
            <Paper className='contact-us-popup'>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            <div className='d-flex justify-content-end'><Close sx={{ fontSize: '28px', color: '#000', cursor: 'pointer' }} onClick={()=>props.close("contactus")} /></div>
                            <div className='contact-us-txt'>Contact Us</div>
                            <div className='we-are-here-txt'> We're here to assist you. Whether you have questions, feedback, or need support, we're just a message away. </div>
                            <div className='d-flex content-block'>
                                <div className='address-block'>
                                    <div className='heading'>Contact Information</div>
                                    <div className='sub-heading'>Customer Support:</div>
                                    <div className='d-flex'><span><img src={emailContactUs} className='contact-icon' /></span><span className='email-txt'>contact@scorenxt.com</span></div>
                                    <div className='sub-heading'>Office Location:</div>
                                    <div className='d-flex feedback-des'> <span><img src={locationIconContactus} className='contact-icon' /></span>
                                        <div> LIDAVI Technologies Pvt Ltd.,<br />
                                            16.,Amaithi solai nagar,<br />
                                            Thirunagar-625006, Madurai, Tamil Nadu, India.
                                        </div>
                                    </div>
                                    <div className='sub-heading'>Social Media:</div>
                                    <div><span><img src={youtubeIcon} className='contact-icon' /></span><span className='email-txt youtube_link'>@scorenxt</span></div>
                                </div>
                                <div className='vertical-line'></div>
                                <div className='feedback-block'>
                                    <div className='heading text-center'>Feedback form</div>
                                    <div className='feedback-des have-suggestion-txt text-center'>Have a suggestion or feedback for us?</div>
                                    <div className='feedback-des text-center'>Fill out our feedback form, and we'll appreciate your input.</div>
                                    <div className='form-block'>
                                        <Paper elevation={3} sx={{ padding: '12px', borderRadius: '15px', width: '394px' }}>
                                            <TextField
                                                hiddenLabel
                                                variant="filled"
                                                size="small"
                                                className='email-box'
                                                fullWidth
                                                placeholder='Your Email'
                                                sx={{
                                                    borderRadius: '4px', border: 'none', outline: 'none', input: {
                                                        "&::placeholder": {    // <----- Add this.
                                                            color: '#3B3A3A', fontSize: '12px', fontWeight: 400, paddingLeft: '6px'
                                                        },
                                                    }
                                                }}
                                                InputProps={{
                                                    disableUnderline: true, // <== added this
                                                }}
                                            />
                                            <TextareaAutosize fullWidth variant="filled" className='feedback-box' minRows={3} maxRows={10} placeholder='Feedback/Suggestions'
                                            />

                                            <div><Checkbox className='signup-checkbox' sx={{ color: "#1953E7", marginLeft: '-10px', '&.Mui-checked': { color: "#1953E7" } }} /><span className='accept-all-txt'>I accept all  <span className='terms-and-condition'>terms and conditions</span></span></div>
                                            <Button
                                                variant="contained"
                                                className='submit-button'
                                            >
                                                Submit
                                            </Button>
                                        </Paper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Paper>
        </Dialog>
    )
}

export default ContactUsDialog;