import './sideBar.css'
import homeIcon from '../assets/icons/HomeIcon1.png'
import myCourseIcon from '../assets/icons/myCourseIcon.png'
import myCourseSelected from '../assets/icons/myCourseSelected.png'
import settingIcon from '../assets/icons/settingIcon.png'
import infoIconSideBar from '../assets/icons/infoIconSideBar.png'
import HomeSelecetedIcon from '../assets/icons/HomeSeleceted.png'
import PurchaseIcon from '../assets/icons/PurchaseIcon.png'
import PurchaseSelected from '../assets/icons/PurchaseSelected.png'
import LogOut from '../assets/icons/LogOut.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Close, ArrowForwardIos } from '@mui/icons-material';
import { decryptDataToken } from '../commonFunction/commonFunction'


const SideBar = () => {

    const [selectedMenu, setSelectedMenu] = useState('home')


    const navigate = useNavigate();

    const urlSplit = window.location.href.split("/");
    const urlValue = urlSplit[urlSplit.length - 1]


    useEffect(() => {
        if (urlValue == "coursePurchase") {
            setSelectedMenu("purchase")
        }
    }, [])


    const updateSelectedMenu = (value, e) => {
        try {
            setSelectedMenu(value)
            if (value == 'mycourse') {
                document.getElementById("sidebar-submenu").style.width = "281px";

                const popup = document.querySelector('.menu-div-overlay');
                popup.classList.add('open');
                setTimeout(() => {
                    document.getElementById("sidebar-submenu-collection").style.display = "block";

                }, 200)
            }
            else {
                document.getElementById("sidebar-submenu").style.transition = "0.5s";
                document.getElementById("sidebar-submenu").style.width = "0px";
                const popup = document.querySelector('.menu-div-overlay');
                popup.classList.remove('open');

                setTimeout(() => {
                    document.getElementById("sidebar-submenu-collection").style.display = "none";

                }, 100)
            }
            if (value == "home") {
                navigate("/home")
            }
            if (value == "purchase") {
                navigate("/allCourses")
            }
        }
        catch (e) {

        }
    }

    const closeSubMenu = () => {
        try {
            document.getElementById("sidebar-submenu").style.transition = "0.5s";
            document.getElementById("sidebar-submenu").style.width = "0px";
            const popup = document.querySelector('.menu-div-overlay');
            popup.classList.remove('open');

            setTimeout(() => {
                document.getElementById("sidebar-submenu-collection").style.display = "none";

            }, 100)

            const urlSplit = window.location.href.split("/");
            const urlValue = urlSplit[urlSplit.length - 1]
            if (urlValue == "home") {
                setSelectedMenu("home")
            }
            else if (urlValue == "coursePurchase" || urlValue == "allCourses") {
                setSelectedMenu("purchase")
            }
            else if (urlValue == "courseSelection" || urlValue == "testCustomization" || urlValue == "practicetestSolution" || urlValue == "myCourses" || urlValue == "viewTestHistory" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution" || urlValue == "testHistorySelection" || urlValue == "eventList") {
                setSelectedMenu("mycourse")
            }
        }
        catch (e) {

        }
    }

    const redirectAllCourses = () => {
        document.getElementById("sidebar-submenu").style.transition = "0.5s";
        document.getElementById("sidebar-submenu").style.width = "0px";
        setTimeout(() => {
            document.getElementById("sidebar-submenu-collection").style.display = "none";
            navigate("/myCourses")
        }, 100)

    }

    const practiceTestClick = () => {
        navigate("/courseSelection",
            { state: { from: "sideBar" } })
    }

    const eventTestClick = () => {
        setTimeout(() => {
            document.getElementById("sidebar-submenu-collection").style.display = "none";
            navigate("/eventList",
                { state: { from: "sideBar" } })

        }, 100)
    }

    const closeOverlay = () => {
        try {
            const popup = document.querySelector('.menu-div-overlay');
            if (popup.classList.contains('open')) {
                document.getElementById("sidebar-submenu").style.transition = "0.5s";
                document.getElementById("sidebar-submenu").style.width = "0px";
                const popup = document.querySelector('.menu-div-overlay');
                popup.classList.remove('open');

                setTimeout(() => {
                    document.getElementById("sidebar-submenu-collection").style.display = "none";
                }, 100)

                const urlSplit = window.location.href.split("/");
                const urlValue = urlSplit[urlSplit.length - 1]
                if (urlValue == "home") {
                    setSelectedMenu("home")
                }
                else if (urlValue == "coursePurchase" || urlValue == "allCourses") {
                    setSelectedMenu("purchase")
                }
                else if (urlValue == "courseSelection" || urlValue == "testCustomization" || urlValue == "practicetestSolution" || urlValue == "myCourses" || urlValue == "viewTestHistory" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution" || urlValue == "testHistorySelection" || urlValue == "eventList") {
                    setSelectedMenu("mycourse")
                }

            }
            else if (popup.classList.contains('transparent')) {
                if (urlValue == "home") {
                    setSelectedMenu("home")
                }
                else if (urlValue == 'courseSelection' || urlValue == "practicetestSolution" || urlValue == 'testCustomization' || urlValue == 'myCourses' || urlValue == "viewTestHistory" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution" || urlValue == "testHistorySelection" || urlValue == "eventList") {
                    setSelectedMenu("mycourse")
                }
                else if (urlValue == 'coursePurchase' || urlValue == 'buyCourse' || urlValue == 'allCourses') {
                    setSelectedMenu("purchase")
                }


            }
        }
        catch (e) {

        }
    }


    const style = {
        width: '200px',
        padding: '11px 15px',
        fontSize: window.innerWidth < 900 ? '13px' : '14px'
    };

    const onLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("preferred_username");
        localStorage.removeItem("userId");
        localStorage.removeItem("code");
        localStorage.removeItem("config");
        navigate("/")
    }


    let showPracticeTest = false;

    if (localStorage.getItem("config")) {
        const config = decryptDataToken(localStorage.getItem("config"))

        console.log("config=", config)

        config.forEach((e) => {
            if (e.course_config.pt === true) {
                return showPracticeTest = true;
            }
        });

    }
    else {
        onLogout()
    }


    return (
        <div className="sidebar-component">
            <div className='sidebar-icon-block'>
                <div onClick={evt => updateSelectedMenu('home', evt)} className={(urlValue == 'home' && selectedMenu == "home") ? 'sidebar-icon selected-border' : 'sidebar-icon'}><div className='d-flex justify-content-center'><img alt="home-icon" src={(urlValue == 'home' && selectedMenu == "home") ? HomeSelecetedIcon : homeIcon} className='home-icon icon-size' /></div><div className={(urlValue == 'home' && selectedMenu == "home") ? 'sidebar-icon-text d-flex justify-content-center show-text' : 'sidebar-icon-text d-flex justify-content-center hide-text'}>Home</div>
                </div>
                <div onClick={evt => updateSelectedMenu('mycourse', evt)} className={((urlValue == 'courseSelection' || urlValue == "practicetestSolution" || urlValue == 'testCustomization' || urlValue == 'myCourses' || urlValue == "viewTestHistory" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution" || urlValue == "testHistorySelection" || urlValue == "eventList" || selectedMenu == "mycourse") && (selectedMenu != "settings" && selectedMenu != "info")) ? 'sidebar-icon selected-border' : 'sidebar-icon'}><div><div className='d-flex justify-content-center'><img alt="my-course-icon" src={((urlValue == 'courseSelection' || urlValue == 'testCustomization' || urlValue == "practicetestSolution" || urlValue == 'myCourses' || urlValue == "viewTestHistory" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution" || urlValue == "testHistorySelection" || urlValue == "eventList" || selectedMenu == "mycourse") && (selectedMenu != "settings" && selectedMenu != "info")) ? myCourseSelected : myCourseIcon} className='my-course-icon icon-size icon-spacing' /></div>
                    <div className={((urlValue == 'courseSelection' || urlValue == 'testCustomization' || urlValue == "viewTestHistory" || urlValue == "viewTestHistoryEventTest" || urlValue == "eventTestSolution" || urlValue == "testHistorySelection" || urlValue == "eventList" || urlValue == 'myCourses' || urlValue == "practicetestSolution" || selectedMenu == "mycourse") && (selectedMenu != "settings" && selectedMenu != "info")) ? 'sidebar-icon-text d-flex justify-content-center show-text' : 'sidebar-icon-text d-flex justify-content-center hide-text'}>My Course</div></div>
                </div>
                <div onClick={evt => updateSelectedMenu('purchase', evt)} className={((((urlValue == 'coursePurchase' || urlValue == 'buyCourse') && (selectedMenu != "settings" && selectedMenu != "info")) || (urlValue == 'allCourses' && (selectedMenu != "settings" && selectedMenu != "info"))) && selectedMenu != "mycourse") ? 'sidebar-icon selected-border' : 'sidebar-icon'}><div><div className='d-flex justify-content-center'><img alt="purchase-icon" src={((((urlValue == 'coursePurchase' || urlValue == 'buyCourse') && (selectedMenu != "settings" && selectedMenu != "info")) || (urlValue == 'allCourses' && (selectedMenu != "settings" && selectedMenu != "info"))) && selectedMenu != "mycourse") ? PurchaseSelected : PurchaseIcon} className='my-course-icon icon-spacing icon-size' /></div>
                    <div className={((((urlValue == 'coursePurchase' || urlValue == 'buyCourse') && (selectedMenu != "settings" && selectedMenu != "info")) || (urlValue == 'allCourses' && (selectedMenu != "settings" && selectedMenu != "info"))) && selectedMenu != "mycourse") ? 'sidebar-icon-text d-flex justify-content-center show-text' : 'sidebar-icon-text d-flex justify-content-center hide-text'}>Purchase</div></div>
                </div>
                <div className='bottom-group-icons'>
                    <div className='settings-block'>
                        <div onClick={evt => updateSelectedMenu('settings', evt)} className={selectedMenu == 'settings' ? 'sidebar-icon selected-border settings-icon' : 'sidebar-icon settings-icon'}><div><div className='d-flex justify-content-center'><img alt="settings-icon" src={settingIcon} className='my-course-icon icon-spacing icon-size' /></div>
                            <div className={selectedMenu == 'settings' ? 'sidebar-icon-text d-flex justify-content-center show-text' : 'sidebar-icon-text d-flex justify-content-center hide-text'}>Settings</div></div>
                        </div>

                    </div>

                    <div onClick={evt => updateSelectedMenu('info', evt)} className={selectedMenu == 'info' ? 'sidebar-icon selected-border info-icon' : 'sidebar-icon info-icon'}><div><div className='d-flex justify-content-center'><img alt="info-icon" src={infoIconSideBar} className='my-course-icon icon-spacing info-icon-size' /></div>
                        <div className={selectedMenu == 'info' ? 'sidebar-icon-text d-flex justify-content-center show-text' : 'sidebar-icon-text d-flex justify-content-center hide-text'}>Info</div></div>
                    </div>
                </div>
            </div>
            <div className='menu-div-overlay' onClick={closeOverlay}>
                <div className='sidebar-submenu' id="sidebar-submenu">
                    <div id='sidebar-submenu-collection'>
                        <div className='submenu-header'><div className='menu-txt'>Menu</div><div><Close className='close-icon' onClick={closeSubMenu} /></div></div>
                        <div className='submenu-row' onClick={redirectAllCourses}><div className='submenu-txt'>Courses</div><div><ArrowForwardIos className='forward-icon' /></div></div>
                        {showPracticeTest && <div className='submenu-row' onClick={practiceTestClick}><div className='submenu-txt'>Practice Test</div><div><ArrowForwardIos className='forward-icon' /></div></div>}
                        <div className='submenu-row' onClick={eventTestClick}><div className='submenu-txt'>Event Test</div><div><ArrowForwardIos className='forward-icon' /></div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideBar;