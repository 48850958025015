import { Close } from '@mui/icons-material';
import { Dialog, createTheme, ThemeProvider, DialogContentText, DialogActions, DialogTitle, Button, DialogContent, Typography } from '@mui/material';
import { DateFormat } from '../Date/DateFormat';
import { TimeFormat } from '../Date/TimeFormat';
const MessageViewDialog = ({ ...props }) => {

    const theme = createTheme({
        typography: {
            fontFamily: 'Avenir',
        }
    });

    return (
        <Dialog open={props.status} maxWidth="sm" fullWidth
            className='dialog-with-action'
            aria-labelledby="draggable-dialog-title"
        >
            <ThemeProvider theme={theme}>

                <DialogTitle>
                    <div><><>{props.title}</><Typography sx={{ fontSize: '12px', color: '#8F9AA2' }}>{DateFormat(props.timeStamp) + " " + TimeFormat(props.timeStamp)}</Typography>
                    </> <Close onClick={props.onConfirm} sx={{ position: 'absolute', cursor: 'pointer', top: 20, right: 20, color: 'var(--grey-color-bottom-nav)' }} /></div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        
                        <Typography sx={{padding:'10px 0px',color:'var(--on-seconary-black)'}}>{props.message}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: '0px 32px 20px 0px', display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={props.url === "" ? props.onConfirm : props.urlRedirect} sx={{ background: 'var(--primary-color)', textTransform: 'none', width: '100px', height: '40px', borderRadius: '20px' }} >{props.url === "" ? "Ok" : "View"}</Button>
                </DialogActions>
            </ThemeProvider>
        </Dialog>
    )
}

export default MessageViewDialog;